import { createWebHistory, createRouter } from 'vue-router'

const routes = [
     {//메인홀
        path: '/',
        name: 'home',
        component: () => import('../views/main/a')
    },
      // 메인type1
      { 
        path: '/main/a', 
        name: '메인홀a',
        component: () => import('../views/main/a'),
    },
     // 메인type2
     { 
        path: '/main/b', 
        name: '메인홀b',
        component: () => import('../views/main/b'),
    },
    // 메인type3
    { 
        path: '/main/c', 
        name: '메인홀c',
        component: () => import('../views/main/c'),
    },
    // 메인type4
    { 
        path: '/main/d', 
        name: '메인홀d',
        component: () => import('../views/main/d'),
    },
    // 메인type5
    { 
        path: '/main/e', 
        name: '메인홀e',
        component: () => import('../views/main/e'),
    },
    // 메인type6
    { 
        path: '/main/f', 
        name: '메인홀f',
        component: () => import('../views/main/f'),
    },
    // 메인type7
    { 
        path: '/main/g', 
        name: '메인홀g',
        component: () => import('../views/main/g'),
    },
    // 전시홀타입1-a
    { 
        path: '/exhibitionHall/type1/a', 
        name: 'exhibitionHallType1_a',
        component: () => import('../views/exhibitionHall/type1/a'),
    },
    // 전시홀타입1-b
    { 
        path: '/exhibitionHall/type1/b', 
        name: 'exhibitionHallType1_b',
        component: () => import('../views/exhibitionHall/type1/b'),
    },
    // 전시홀타입2-a
    { 
        path: '/exhibitionHall/type2/a', 
        name: 'exhibitionHallType2_a',
        component: () => import('../views/exhibitionHall/type2/a'),
    },
    // 전시홀타입3-a
    { 
        path: '/exhibitionHall/type3/a', 
        name: 'exhibitionHallType3_a',
        component: () => import('../views/exhibitionHall/type3/a'),
    },
    // 전시홀타입4-a
    { 
        path: '/exhibitionHall/type4/a', 
        name: 'exhibitionHallType4_a',
        component: () => import('../views/exhibitionHall/type4/a'),
    },
    // 전시홀타입5
    { 
        path: '/exhibitionHall/type5/a', 
        name: 'exhibitionHallType5',
        component: () => import('../views/exhibitionHall/type5/a'),
    },
     // 전시홀타입6
    { 
        path: '/exhibitionHall/type6/a', 
        name: 'exhibitionHallType6',
        component: () => import('../views/exhibitionHall/type6/a'),
    },
     // 전시홀타입7
    { 
        path: '/exhibitionHall/type7/a', 
        name: 'exhibitionHallType7',
        component: () => import('../views/exhibitionHall/type7/a'),
    },
    // type1 부스
    { 
        path: '/booth/a', 
        name: '부스1',
        component: () => import('../views/booth/a'),
    },
    // type2 부스
    { 
        path: '/booth/b', 
        name: '부스2',
        component: () => import('../views/booth/b'),
    },
    // type3 부스
    { 
        path: '/booth/c', 
        name: '부스3',
        component: () => import('../views/booth/c'),
    },
    // type4 부스
    { 
        path: '/booth/d', 
        name: '부스4',
        component: () => import('../views/booth/d'),
    },
    // type5 부스
    { 
        path: '/booth/e', 
        name: '부스5',
        component: () => import('../views/booth/e'),
    },
    // type6 부스
    { 
        path: '/booth/f', 
        name: '부스6',
        component: () => import('../views/booth/f'),
    },
     // type7 부스
    { 
        path: '/booth/g', 
        name: '부스7',
        component: () => import('../views/booth/g'),
    },
     // type8 부스
    { 
        path: '/booth/h', 
        name: '부스8',
        component: () => import('../views/booth/h'),
    },
      // type9 부스
    { 
        path: '/booth/i', 
        name: '부스9',
        component: () => import('../views/booth/i'),
    },
    // type10 부스
    { 
        path: '/booth/j', 
        name: '부스10',
        component: () => import('../views/booth/j'),
    },
    // type11 부스
    { 
        path: '/booth/k', 
        name: '부스11',
        component: () => import('../views/booth/k'),
    },
    // type12 부스
    { 
        path: '/booth/l', 
        name: '부스12',
        component: () => import('../views/booth/l'),
    },
    // 미디어홀a
    { 
        path: '/lecturehall/a', 
        name: '미디어홀a',
        component: () => import('../views/lecturehall/a'),
    },
    // 미디어홀b
    { 
        path: '/lecturehall/b', 
        name: '미디어홀b',
        component: () => import('../views/lecturehall/b'),
    },
    // 미디어홀c
    { 
        path: '/lecturehall/c', 
        name: '미디어홀c',
        component: () => import('../views/lecturehall/c'),
    },
     // 미디어홀d
    { 
        path: '/lecturehall/d', 
        name: '미디어홀d',
        component: () => import('../views/lecturehall/d'),
    },
     // 미디어홀e
    { 
        path: '/lecturehall/e', 
        name: '미디어홀e',
        component: () => import('../views/lecturehall/e'),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router