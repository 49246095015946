import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue3 from 'bootstrap-vue-3'


/* https://bootstrap-vue-next.github.io/bootstrap-vue-next/ */
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import "@/assets/css/style.css";

// 아이콘 https://remixicon.com/
import 'remixicon/fonts/remixicon.css'

const app = createApp(App);

app.use(BootstrapVue3)
app.use(router).mount('#app')
