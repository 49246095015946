<template>
   <footer  id="footer" class="footer p-5">
        <div class="footerlogo">
            <a href="/">
                <img src="@/assets/img/logo-dark.png" alt="로고">
            </a>

        </div>

        <div class="footer_info"> 
            <div class="left">
                <ul class="d-flex">
                    <li>대표 <span class="black">홍길동</span></li>
                    <li>사업자등록번호 <span class="black">123-45-67890</span></li>
                </ul>
                <ul>
                    <li>주소 <span class="black">(13120) 경기도 성남시 수정구 성남대로</span></li>
                    <li>Tel <span class="black">031-000-0000</span></li>
                </ul>
            </div>

            <div class="right">
                <p>contact</p>
                <ul>
                    <li><i class="ri-phone-line"></i> 031-000-0000</li>
                    <li><i class="ri-mail-line"></i> abcdef@naver.com</li>
                </ul>
            </div>

        </div>

        <p class="footer_copy">COPYRIGHT© 2023</p>
    
    </footer>
</template>
