<script>


export default {
  props: {
    type: {
    },
    width: {
    },
  },
  computed: {

  },
  components: {  },
  data() {
    return {
    };
  },
  methods: {

    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    setLanguage(locale) {
      i18n.locale = locale;
      this.current_language = i18n.locale;
    },
  },
  mounted() {

  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            case "colored":
              document.body.setAttribute("data-topbar", "colored");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header container">
      <div class="d-flex w-100 justify-content-between">
        <!-- LOGO -->
        <div class="navbar-brand-box">

          <a href="/main/a" class="logo logo-light">
            <span class="logo-lg">
              <img src="@/assets/img/logo-light.png" alt height="20" />
            </span>
          </a>
        </div>

        
      </div>

      <div class="mobMenu">

        <button type="button" class="btn btn-sm mobbtn" data-toggle="collapse"
          @click="toggleMenu">
          <i class="ri-menu-2-line align-middle"></i>
        </button>

      </div>

    </div>
  </header>
</template>