<template>
    <div class="topnav">
      <div class="container-fluid">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
          <div class="collapse navbar-collapse justify-content-center" id="topnav-menu-content">
            <ul class="navbar-nav">

              <li class="nav-item  ">
                <a class="nav-link dropdown-toggle arrow-none" @click="onMenuClick" href="javascript: void(0);"
                  id="topnav-components" role="button">
                  <i class="ri-home-line"></i>메인홀
                </a>

                <div class="dropdown-menu row" aria-labelledby="topnav-dashboard">
                  <router-link class="col dropdown-item side-nav-link-ref" to="/main/a">type1</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/main/b">type2</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/main/c">type3</router-link>
                  <!-- <router-link class="col dropdown-item side-nav-link-ref" to="/main/d">type4</router-link> -->
                  <router-link class="col dropdown-item side-nav-link-ref" to="/main/e">type4</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/main/f">type5</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/main/g">type6</router-link>
                </div>

              </li>

              <li class="nav-item  ">
                <a class="nav-link dropdown-toggle arrow-none" @click="onMenuClick" href="javascript: void(0);"
                  id="topnav-components" role="button">
                  <i class="ri-bank-line"></i>전시홀
                </a>

                <div class="dropdown-menu row" aria-labelledby="topnav-dashboard">
                  <router-link class="col dropdown-item side-nav-link-ref" to="/exhibitionHall/type1/a">type1</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/exhibitionHall/type2/a">type2</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/exhibitionHall/type3/a">type3</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/exhibitionHall/type4/a">type4</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/exhibitionHall/type5/a">type5</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/exhibitionHall/type6/a">type6</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/exhibitionHall/type7/a">type7</router-link>
                </div>

              </li>

              <li class="nav-item  ">
                <a class="nav-link dropdown-toggle arrow-none" @click="onMenuClick" href="javascript: void(0);"
                  id="topnav-components" role="button">
                  <i class="ri-bank-line"></i>부스
                </a>

                <div class="dropdown-menu row" aria-labelledby="topnav-dashboard">
                  <router-link class="col dropdown-item side-nav-link-ref" to="/booth/j">type1</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/booth/k">type2</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/booth/l">type3</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/booth/d">type4</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/booth/e">type5</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/booth/f">type6</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/booth/a">type7</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/booth/b">type8</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/booth/c">type9</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/booth/g">type10</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/booth/h">type11</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/booth/i">type12</router-link>
                </div>

              </li>

              <li class="nav-item  ">
                <a class="nav-link dropdown-toggle arrow-none" @click="onMenuClick" href="javascript: void(0);"
                  id="topnav-components" role="button">
                  <i class="ri-live-line"></i>미디어홀
                </a>

                <div class="dropdown-menu row" aria-labelledby="topnav-dashboard">
                  <router-link class="col dropdown-item side-nav-link-ref" to="/lecturehall/a">type1</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/lecturehall/b">type2</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/lecturehall/c">type3</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/lecturehall/d">type4</router-link>
                  <router-link class="col dropdown-item side-nav-link-ref" to="/lecturehall/e">type5</router-link>
                </div>

              </li>

            </ul>
          </div>
        </nav>
      </div>
    </div>
  </template>

<script>


export default {
  data() {
    return {

    };
  },
  mounted() {
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
        }
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("active");
          var childAnchor = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("active");
        }

        const parent4 = parent3.parentElement;
        if (parent4) parent4.classList.add("active");
        const parent5 = parent4.parentElement;
        if (parent5) parent5.classList.add("active");
      }
    }
  },
  methods: {

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    change_layout(layout) {
      return this.changeLayoutType({ layoutType: layout });
    },
    topbarLight() {
      document.body.setAttribute("data-topbar", "light");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedWidth() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-topbar", "light");
      document.body.setAttribute("data-topbar", "dark");
    }
  }
};
</script>


